// https://reativa.dev/images/logo-light.png
// https://scontent.fsjk1-1.fna.fbcdn.net/v/t1.0-9/83652091_136284957845200_8237786461009608704_n.png?_nc_cat=109&_nc_ohc=BC9ilU71jTAAX_V9Elf&_nc_ht=scontent.fsjk1-1.fna&oh=c145d921258900ee4f26d0a56fe2ecdb&oe=5EB8C623

const config = {
  gatsby: {
    pathPrefix: '/',
    siteUrl: 'https://nodejs.reativa.dev',
    gaTrackingId: 'UA-155080700-2'
  },
  header: {
    logo:
      'https://scontent.fsjk1-1.fna.fbcdn.net/v/t1.0-9/83652091_136284957845200_8237786461009608704_n.png?_nc_cat=109&_nc_ohc=BC9ilU71jTAAX_V9Elf&_nc_ht=scontent.fsjk1-1.fna&oh=c145d921258900ee4f26d0a56fe2ecdb&oe=5EB8C623',
    logoLink: 'http://bit.ly/reativa-insta',
    title: 'NodeJS para iniciantes | Reativa',
    githubUrl: 'https://github.com/reativa',
    helpUrl: '',
    tweetText: '',
    links: [{ text: '', link: '' }],
    search: {
      enabled: false,
      indexName: '',
      algoliaAppId: process.env.GATSBY_ALGOLIA_APP_ID,
      algoliaSearchKey: process.env.GATSBY_ALGOLIA_SEARCH_KEY,
      algoliaAdminKey: process.env.ALGOLIA_ADMIN_KEY
    }
  },
  sidebar: {
    forcedNavOrder: [
      '/introduction', '/0001-node-introduction/index', '/0002-node-history/index', '/0003-node-installation/index', '/0004-node-javascript-language/index', '/0005-node-difference-browser/index', '/0006-v8/index', '/0007-node-run-cli/index', '/0008-node-terminate-program/index', '/0009-node-environment-variables/index', '/0011-node-repl/index', '/0012-node-cli-args/index', '/0013-node-output-to-cli/index', '/0014-node-input-from-cli/index', '/0015-node-export-module/index', '/0016-npm/index', '/0017-where-npm-install-packages/index', '/0018-how-to-use-npm-package/index', '/0019-package-json/index', '/0020-package-lock-json/index', '/0021-npm-know-version-installed/index', '/0022-npm-install-previous-package-version/index', '/0023-update-npm-dependencies/index', '/0024-npm-semantic-versioning/index', '/0025-npm-uninstall-packages/index', '/0026-npm-packages-local-global/index', '/0027-npm-dependencies-devdependencies/index', '/0028-npx/index', '/0029-node-event-loop/index', '/0030-node-process-nexttick/index', '/0031-node-setimmediate/index', '/0032-javascript-timers/index', '/0033-javascript-callbacks/index', '/0034-javascript-promises/index', '/0035-javascript-async-await/index', '/0036-node-event-emitter/index', '/0037-node-http-server/index', '/0038-node-make-http-requests/index', '/0039-node-http-post/index', '/0040a-node-request-data/index', '/0040b-node-file-descriptors/index', '/0041-node-file-stats/index', '/0042-node-file-paths/index', '/0043-node-reading-files/index', '/0044-node-writing-files/index', '/0045-node-folders/index', '/0046-node-module-fs/index', '/0047-node-module-path/index', '/0048-node-module-os/index', '/0049-node-module-events/index', '/0050-node-module-http/index', '/0051-node-buffers/index', '/0052-nodejs-streams/index', '/0053-node-difference-dev-prod/index', '/0054-node-exceptions/index', '/0055-node-inspect-object/index'
    ],
    collapsedNav: [],
    links: [{ text: 'Reativa Instagram', link: 'http://bit.ly/reativa-insta' }],
    frontline: true,
    ignoreIndex: true
  },
  siteMetadata: {
    title: 'Maratona Programação | Reativa',
    description:
      'Reativa Tecnologia - Plataforma Online para desenvolvedores http://nodejs.reativa.dev ',
    ogImage: null,
    docsLocation: '',
    favicon:
      'https://scontent.fsjk1-1.fna.fbcdn.net/v/t1.0-9/83652091_136284957845200_8237786461009608704_n.png?_nc_cat=109&_nc_ohc=BC9ilU71jTAAX_V9Elf&_nc_ht=scontent.fsjk1-1.fna&oh=c145d921258900ee4f26d0a56fe2ecdb&oe=5EB8C623'
  },
  pwa: {
    enabled: false, // disabling this will also remove the existing service worker.
    manifest: {
      name: 'Reativa Tecnologia',
      short_name: 'PlataformaReativa',
      start_url: '/',
      background_color: '#6b37bf',
      theme_color: '#6b37bf',
      display: 'standalone',
      crossOrigin: 'use-credentials',
      icons: [
        {
          src: 'src/pwa-512.png',
          sizes: `512x512`,
          type: `image/png`
        }
      ]
    }
  }
}

module.exports = config
